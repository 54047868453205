<template>
     <div>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                        <div class="card">
                            <div class="card-body table-responsive table-head">
                                <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert" ></custom-alert>
                                <div class="row bor-bot pb-3">
                                    <div class="col-md-8 pl-0">
                                        <h1 class="m-0 text-dark pt-2 pb-2">Group Members ( <a :href="'/admin/group/edit/'+$route.params.gid" class="text-dark">{{ group_name }} </a>) 
                                            <router-link :to="`/admin/group`" class="ml-1 btn btn-secondary"> <i class="fa fa-arrow-left" aria-hidden="true"></i> Back to Groups</router-link>      
                                         </h1>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="text-right pb-3">
                                            <ol class="breadcrumb float-sm-right">
                                                <li class="breadcrumb-item">
                                                    <a href="/admin/dashboard">Home</a>
                                                </li>
                                                <li class="breadcrumb-item">
                                                    <a href="/admin/group">Group</a>
                                                </li>
                                                <li class="breadcrumb-item"><Breadcrumbs /></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div> 
                                                                 
                                <div> 
                                    <div class="row">        
                                        <div class="col-lg-8 mr-0 pr-0">
                                            <div class="mt-3">
                                            <b-form inline>
                                            <label class="mr-sm-2 font-weight-normal">Show</label>
                                            <b-form-select
                                                id="inline-form-custom-select-pref"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                v-model="perPage"
                                                :options="viewEntryOptions"
                                                size="sm"
                                            ></b-form-select
                                            >entries
                                            </b-form>
                                            </div>                                    
                                        </div>
                                        <div class="col-lg-4  text-right pr-0">
                                            <div class=" card-text d-flex align-items-center float-right"> 
                                                <div class="mt-3"> 
                                                    <b-input-group size="sm">
                                                    <b-form-input
                                                        id="filter-input"
                                                        v-model="filter"
                                                        type="search"
                                                        placeholder="Search"
                                                    ></b-form-input>
                                                    </b-input-group>
                                                </div>
                                                <div class="mt-3 ml-3">
                                                <b-button v-b-modal.member-lookup-modal class="btn btn-blue">
                                                   <i class="fa fa-plus" aria-hidden="true"></i> Add Member
                                                </b-button>
                                                </div>
                                            </div>
                                        </div>                                     
                                    </div>                                    
                                    <b-table
                                        ref="groupTable"
                                        id="group-table"
                                        :items="groupmembers"
                                        :fields="groupTbl"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        :busy="!loaded"
                                        class="mt-3"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                        @filtered="onFiltered"
                                        hover
                                        show-empty
                                    >   
                                        <template #cell(confirmation_code)="row">
                                            {{ row.item.confirmation_code }}
                                        </template>
                                        <template #cell(full_name)="row">
                                            {{ row.item.personal_details.full_name }}
                                        </template>                                              
                                        <template #cell(dob)="row">
                                            {{ row.item.personal_details.dob }}
                                        </template>    
                                        <template #cell(gender)="row">
                                            {{ row.item.personal_details.gender }}
                                        </template>    
                                        <template #cell(name)="row">
                                            {{ row.item.membership.name }}
                                        </template>    
                                        <template #cell(status_text)="row">
                                            {{ row.item.status_text =='' ? 'N/A' : row.item.status_text}}
                                        </template>   
                                        <template #cell(actions)="row">
                                            <div class="btn-group btn-group-sm">
                                                <button class="btn btn-danger" @click="deleteRow($route.params.gid,row.item.id)"><i class="fas fa-trash"></i></button>
                                            </div>
                                        </template> 
                                        <template #empty="scope">                                                 
                                            <h4 class="text-center">{{ scope.emptyText }}</h4>
                                        </template>                                             
                                        <template #table-busy>
                                            <div class="text-center text-primary my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Loading...</strong>
                                            </div>
                                        </template>
                                    </b-table>
                                    <br />       
                                    <span v-if="loaded && rows">Showing {{ (( currentPage * perPage) - perPage) + 1  }} to {{ (currentPage * perPage) > rows ? rows : currentPage * perPage }} of {{ rows }} entries</span>                             
                                    <b-pagination v-if="loaded && this.groupmembers.length"
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                        prev-text="Previous"
                                        next-text="Next"
                                        aria-controls="groupTable"
                                        class="float-right custom-color"
                                        hide-goto-end-buttons>
                                    </b-pagination>                                                                  
                                </div>                                
                            </div>
                        </div>
                    </section>                    
                </div>
            </div>
        </section>
        <member-lookup @member-lookup-data="AssignMembertoGroup"></member-lookup>
     </div>
</template>

<script>
    import * as $ from "jquery"; 
    import moment from "moment";
    import axios from 'axios';
    import Select2 from "v-select2-component";
    import CustomAlert from "../../CustomAlert";
    import MemberLookup from "./MemberLookup";
    export default {
        components: {CustomAlert,MemberLookup},
        data() {
            return {
                memberIds:[],
                members:[],
                group_name:'',
                form:{
                    member_id:[]
                },
                groupmembers:[],                
                groupTbl: [
                    {
                        key: "confirmation_code",
                        label:'Member ID',
                        sortable: false,
                    },
                    {
                        key: "full_name",
                        label:'Name',
                        sortable: false,
                    },                    
                    {
                        key: "DOB",
                        sortable: false,
                    },
                    {
                        key: "Gender",
                        sortable: false,
                    },
                    {
                        key: "name",
                        label:'Membership',
                        sortable: false,
                    },
                    {
                        key: "status_text",
                        label: "Status",
                        sortable: false,
                    },
                    {
                        key: "actions",
                        sortable: false,
                    }
                ],
                loaded: false,
                perPage: 10,
                currentPage: 1,
                filter: null,
                filterOn: [],
                viewEntryOptions: [
                    { value: 5, text: '5' },
                    { value: 10, text: '10' },
                    { value: 20, text: '20' },
                    { value: 20, text: '50' },
                    { value: 20, text: '100' },
                ],
            }
        },
        methods:{
            onFiltered(filteredItems) {               
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            getGroupdetails()
            {
                axios.get(this.basePath+'api/admin/group-members/'+this.$route.params.gid, { headers: this.adminHeaders })
                .then((res) => {
                    this.group_name=res.data.data.name;    
                    this.groupmembers=res.data.data.members;    
                    this.loaded = true;                                          
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;
                
                }).finally(() => {
                    
                }); 
            },
            AssignMembertoGroup(data)
            {
                if(data != undefined)
                {  
                    this.loaded = false;                  
                    this.form.group_id = this.$route.params.gid
                    this.form.member_id = data.id
                    axios.post(this.basePath+'api/admin/group/assign-members', this.form, { headers: this.adminHeaders })
                    .then((res) => {
                        this.displayAlert = true, 
                        this.alertMessage = res.data.message;                         
                        this.getGroupdetails();
                    })
                    .catch((error) => {
                        console.log('Error:'+ error);               
                        return false;
                    
                    }).finally(() => {
                        
                    }); 

                }
            },
            deleteRow(gid,mid) {      
                this.displayAlert = false;                
                this.$bvModal.msgBoxConfirm("Are you sure to remove member ?" ,{
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                centered: false
                }).then(value => {                     
                    if (String(value) == "true") {
                        this.loaded = false; 
                        axios.delete(process.env.VUE_APP_URL + 'api/admin/group/delete-members/' + gid +'/'+mid,{ headers: this.adminHeaders})
                        .then(res => {
                            this.displayAlert = true, 
                            this.alertMessage = res.data.message; 
                            this.getGroupdetails();                         
                        })
                        .catch(error => {
                            error => this.errors = error.response.data
                        });
                    }                    
                })
                .catch(err => {
                console.log(err);
                });
                return false;

                // if(confirm("Are you sure to remove member ?")){
                // axios.delete(process.env.VUE_APP_URL + 'api/admin/group/delete-members/' + gid +'/'+mid,{ headers: this.adminHeaders})
                //     .then(res => {
                //         this.displayAlert = true, 
                //         this.alertMessage = res.data.message; 
                //         this.getGroupdetails();                         
                //     })
                //     .catch(error => {
                //         error => this.errors = error.response.data
                //     });
                // }
                // return false;
            },
             
        },
        mounted()
        {
            this.getGroupdetails();             
        },
        computed: {
            rows() {
                return this.groupmembers.length
            },
        }
    }
</script>